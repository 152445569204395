<template>
  <v-text-field
    :label="label"
    :value="valueDisplay"
    readonly
    filled
    background-color="transparent"
    hide-details
  >
    <template slot="append">
      <v-btn
        icon
        tile
        @click="show = true"
        v-show="!(options && options.readonly)"
      >
        <v-icon>mdi-square-edit-outline</v-icon>
      </v-btn>
      <v-dialog v-model="show" width="480" persistent>
        <v-card>
          <v-card-title>{{ label }}选择</v-card-title>
          <v-divider></v-divider>
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="items"
            :single-select="singleSelect"
            item-key="id"
            show-select
            hide-default-header
          ></v-data-table>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="success" @click="submit">确定</v-btn>
            <v-btn text @click="show = false">取消</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-text-field>
</template>

<script>
import proto from "../../utils/proto";
import client from "../../utils/client";
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: ["label", "value", "options"],
  data() {
    return {
      show: false,

      headers: [
        {
          text: "名称",
          value: "name",
          sortable: false,
        },
      ],
      items: [],
      selected: [],
    };
  },
  computed: {
    valueDisplay() {
      let list;
      if (this.singleSelect) list = [this.value];
      else list = this.value;
      let names = [];
      for (let i in list) {
        if (list[i]) {
          let n = [];
          if (list[i].pname) n.push(list[i].pname);
          if (list[i].name) n.push(list[i].name);
          names.push(n.join("-"));
        }
      }
      return names.join(",");
    },
    singleSelect() {
      return Object.prototype.toString.call(this.value) !== "[object Array]";
    },
  },
  watch: {
    show: {
      handler(val) {
        if (val) {
          let result = [];
          let list;
          if (this.singleSelect) list = [this.value];
          else list = this.value;
          for (let i in list) {
            if (list[i]) result.push({ id: list[i].id, name: list[i].name });
          }
          this.selected = result;
          this.initSource();
        }
      },
    },
  },
  methods: {
    async initSource() {
      if (this.options) {
        switch (this.options.type) {
          case "period":
            this.getPeriods();
            break;
          case "receiver":
            this.getReceiver();
            break;
          case "notifier":
            this.getNotifier();
            break;
          case "person":
            this.getPerson();
            break;
        }
      }
    },
    async getPeriods() {
      let result = [];
      client.$emit(
        "startBusy",
        "ObjectEditor.getPeriods",
        "正在获取时段,请稍候..."
      );
      await proto.sleep(100);
      try {
        let res = await client.send(proto.MESSAGE_TYPE.periodMessage, {
          mcd: {
            operate: proto.OperateMode.retrieveOpt,
            range: "0",
          },
        });
        if (res.periods) {
          res.periods.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
          for (let i in res.periods) {
            result.push({ id: res.periods[i].id, name: res.periods[i].name });
          }
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ObjectEditor.getPeriods");
      this.items = result;
    },
    async getReceiver() {
      let result = [];
      client.$emit(
        "startBusy",
        "ObjectEditor.getReceiver",
        "正在获取接警组,请稍候..."
      );
      await proto.sleep(100);
      try {
        let res = await client.send(
          proto.MESSAGE_TYPE.receivergroupMessage,
          {
            mcd: {
              operate: proto.OperateMode.retrieveOpt,
              range: "0",
            },
          }
        );
        if (res.groups) {
          res.groups.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
          for (let i in res.groups) {
            result.push({ id: res.groups[i].id, name: res.groups[i].name });
          }
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ObjectEditor.getReceiver");
      this.items = result;
    },
    async getNotifier() {
      let result = [];
      client.$emit(
        "startBusy",
        "ObjectEditor.getNotifier",
        "正在获取告警发送器,请稍候..."
      );
      await proto.sleep(100);
      try {
        let res = await client.send(proto.MESSAGE_TYPE.notifierMessage, {
          mcd: {
            operate: proto.OperateMode.retrieveOpt,
            range: "0",
            parentId: client.project.id,
          },
        });
        if (res.notifiers) {
          for (let i in res.notifiers) {
            if (res.notifiers[i].spot) {
              let spot = res.notifiers[i].spot;
              let name = [];
              if (spot.pname) name.push(spot.pname);
              if (spot.name) name.push(spot.name);
              result.push({
                id: spot.id,
                name: name.join("-"),
              });
            }
          }
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ObjectEditor.getNotifier");
      this.items = result;
    },
    async getPerson() {
      let result = [];
      client.$emit(
        "startBusy",
        "ObjectEditor.getPerson",
        "正在获取用户,请稍候..."
      );
      await proto.sleep(100);
      try {
        let res = await client.send(proto.MESSAGE_TYPE.personMessage, {
          mcd: {
            operate: proto.OperateMode.retrieveOpt,
            range: "0",
          },
          orgGroup: 0,
        });
        if (res.Persons) {
          res.Persons.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
          for (let i in res.Persons) {
            result.push({
              id: res.Persons[i].uuid,
              name: `${res.Persons[i].name}（${res.Persons[i].orgname}）`,
            });
          }
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ObjectEditor.getPerson");
      this.items = result;
    },
    submit() {
      if (this.singleSelect) {
        if (this.selected.length) {
          this.$emit("change", this.selected[0]);
        } else {
          this.$emit("change", { id: "", name: "" });
        }
      } else {
        this.$emit("change", this.selected);
      }
      this.show = false;
    },
  },
};
</script>
